/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react'

import axios from 'axios'
import Moment from 'moment'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'

const BannerSlider = dynamic(() => import('../components/Home/BannerSlider'), { ssr: false })

const AboutSection = dynamic(() => import('../components/Home/AboutSection'), { ssr: false })
const OpenDataSection = dynamic(() => import('../components/Home/OpenDataSection'), { ssr: false })
// const EventSection = dynamic(() => import('../components/Home/EventSection'), { ssr: false })
const PartnerSlider = dynamic(() => import('../components/Home/PartnerSlider'), { ssr: false })
const NewsSlider = dynamic(() => import('../components/Home/NewsSlider'), { ssr: false })
const ServicesSlider = dynamic(() => import('../components/Home/ServicesSlider'), { ssr: false })
export default function Home() {
  const { locale } = useRouter()
  const t = useTranslations('home')
  const [bannerContent, setBannerContent] = useState({})
  const [homeStatistic, setHomeStatistic] = useState({})
  const [aboutStatistic, setAboutStatistic] = useState({})
  const [serviceContent, setServiceContent] = useState({})
  const [services, setServices] = useState([])
  const [news, setNews] = useState([])
  const [patners, setPatners] = useState([])
  const fetchBanners = async () => {
    const menu = await axios({
      method: 'GET',
      url: `/sliders`,
      headers: {
        'Access-Control-Allow-Headers': '*',
      },
    })
    setBannerContent(menu?.data)
  }

  const fetchHomeStatistic = async () => {
    const response = await axios({
      method: 'GET',
      url: `homepage/translation`,
    })
    setHomeStatistic(response?.data)
  }

  const fetchAboutStatistic = async () => {
    const response = await axios({
      method: 'GET',
      url: `/aboutblockapi`,
    })
    setAboutStatistic(response?.data)
  }
  const fetchServiceContent = async () => {
    const response = await axios({
      method: 'GET',
      url: `/aboutblock`,
    })
    setServiceContent(response?.data)
  }
  const fetchServices = async () => {
    const response = await axios({
      method: 'GET',
      url: `/last-services`,
    })
    setServices(response?.data)
  }

  const fetchNews = async () => {
    const response = await axios({
      method: 'GET',
      url: `/last-news`,
    })
    setNews(response?.data)
  }
  const fetchPatnerStatistic = async () => {
    const response = await axios({
      method: 'GET',
      url: `/system`,
    })
    setPatners(response?.data)
  }

  useEffect(() => {
    fetchBanners()
    fetchHomeStatistic()
    fetchAboutStatistic()
    fetchServiceContent()
    fetchServices()
    fetchNews()
    fetchPatnerStatistic()
    //fetchAboutLogo()
    //fetchArabicCalligraphyContent()
  }, [locale])

  const NewsData = news.map((item) => {
    return {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      headline: item?.title,
      image: item?.field_news_thumbnail,
      datePublished: item?.created,
      dateModified: item?.created,
      author: {
        '@type': 'Person',
        name: item?.title.substring(0, 100) + '...',
        url: `${process.env.NEXT_PUBLIC_BASE_URL}/news/${item?.nid}`,
      },
    }
  })
  const aboutData = {
    '@context': 'https://schema.org',
    '@type': 'ProfilePage',
    dateCreated: Moment(new Date()).format('YYYY-MM-DD'),
    dateModified: Moment(new Date()).format('YYYY-MM-DD'),
    mainEntity: {
      '@type': 'Person',
      name: 'About MIM',
      alternateName: aboutStatistic?.blockdata?.title,
      identifier: 'About MIM',
      description: aboutStatistic?.blockdata?.body,
      image: aboutStatistic?.blockdata?.image,
    },
  }
  const serviceData = services.map((item) => {
    return {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: item?.title,
      description: item?.body,
      review: {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: Number(item?.field_rate_value),
          bestRating: 5,
        },
        author: {
          '@type': 'Person',
          name: 'MIM',
        },
      },
    }
  })

  return (
    <>
      <Head>
        <title>{t('mim')}</title>
        <meta name="description" content={t('desc')} />
        {/* for services seo  structured data */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(serviceData) }}
          key="services-jsonld"
        />
        {/* for news seo  structured data */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(NewsData) }}
          key="news-jsonld"
        />
        {/* for about seo  structured data */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(aboutData) }}
          key="about-jsonld"
        />
      </Head>

      <BannerSlider bannerContent={bannerContent} />

      {aboutStatistic?.blockdata && <AboutSection aboutStatistic={aboutStatistic} homeStatistic={homeStatistic} />}

      {aboutStatistic?.statistic?.length > 0 && (
        <OpenDataSection aboutStatistic={aboutStatistic} homeStatistic={homeStatistic} />
      )}

      <ServicesSlider homeStatistic={homeStatistic} serviceContent={serviceContent} services={services} />

      <NewsSlider homeStatistic={homeStatistic} news={news} />

      {/* <EventSection homeStatistic={homeStatistic} /> */}

      <PartnerSlider homeStatistic={homeStatistic} patners={patners} />
    </>
  )
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      messages: (await import(`../locales/home/${locale}.json`)).default,
    },
  }
}
